import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalStateType } from '../../interfaces';
import { InitializeGlobalData } from '../../utils';

const initialState: GlobalStateType = InitializeGlobalData();

const globalSlice = createSlice({
  name: 'conv',
  initialState,
  reducers: {
    setUrlChatbot: (state: GlobalStateType, action: PayloadAction<GlobalStateType>) => {
      state.URL_CHATBOT = action.payload.URL_CHATBOT;
    },
    setTitleChatbot: (state: GlobalStateType, action: PayloadAction<GlobalStateType>) => {
      state.TITLE_CHATBOT = action.payload.TITLE_CHATBOT;
    },
  },
});

export default globalSlice.reducer;

export const { setUrlChatbot, setTitleChatbot } = globalSlice.actions;
