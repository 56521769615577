import React, { FC } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { PropsHeader } from '../../interfaces';
import { useAppSelector } from '../../redux';

import stylesChat from '../chat/Chat.module.css';
import styles from './Header.module.css';

const Header: FC<PropsHeader> = () => {
  const global = useAppSelector((state) => state.global);

  const visibleChat = () => {
    let chat = document.getElementById('root_chatbot_react') || document.createElement('div');
    chat.className = stylesChat.animation_slideout;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p>{global.TITLE_CHATBOT}</p>
      </div>
      <AiOutlineCloseCircle size={30} color="#717171" onClick={visibleChat} />
    </div>
  );
};

export default Header;
