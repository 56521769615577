import React, { FC } from 'react';

import { PropsIcon } from '../../interfaces';

import stylesChat from '../../components/chat/Chat.module.css';

const Icon: FC<PropsIcon> = () => {

  const visibleChat = () => {
    let chat = document.getElementById('root_chatbot_react') || document.createElement('div');
    if (chat.className.includes('slidein')) chat.className = stylesChat.animation_slideout;
    else chat.className = stylesChat.animation_slidein;
  };

  return <img src="/logo_circulo.png" alt="Robot" onClick={visibleChat} />;
};
// const Icon: FC<PropsIcon> = ({ changeState }) => {
//   return <img src="/logo_circulo.png" alt="Robot" onClick={changeState} />;
// };

export default Icon;
