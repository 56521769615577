import { GlobalData, GroupMessage, Message, UserData } from '../interfaces';

const InitializeMessage = (message = '', type = '', nodoActual = 1): Message => ({ message, type, nodoActual });

const InitializeGroupMessage = (owner = 0, messages: Message[] = []): GroupMessage => ({ owner, messages, length: messages.length });

const InitializeUserData = (): UserData => ({ senderId: `WebChat_${new Date().getTime().toString()}` });

const InitializeGlobalData = (URL_CHATBOT: string = 'http://localhost:3000', TITLE_CHATBOT: string = 'Web Chat'): GlobalData => ({
  URL_CHATBOT,
  TITLE_CHATBOT,
});

export { InitializeMessage, InitializeGroupMessage, InitializeUserData, InitializeGlobalData };
