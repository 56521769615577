/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';

import { PropsMessageRobot } from '../../interfaces';
import MessageDocument from '../messageDocument/MessageDocument';

import styles from './MessageRobot.module.css';

const MessageRobot: FC<PropsMessageRobot> = ({ message, first = false, last = false, unique = false, tipo, nodoActual }) => {
  // Tipos de archivos:
  // 0 -> Texto
  // 1 -> Imagen
  // 2 -> Video
  // 3 -> Audio
  // 4 -> PDF
  const [typeMessage, setTypeMessage] = useState<number>(0);

  const pRef = React.useRef<HTMLParagraphElement | null>(null);

  const validateURL = () => {
    const auxMessage = message.split(/\n/g);

    const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/g;
    const regURL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    const regURL2 = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}:[0-9]{1,5}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    let aux = '';
    auxMessage.forEach((e2, idx) => {
      let aux2 = '';
      e2.split(/[\s\r]/g).forEach((e, idx2) => {
        const auxReg = e.match(regURL);
        const auxReg2 = e.match(regURL2);

        if (auxReg || auxReg2) {
          if (!e.match(regEmail) && auxReg && e.length === auxReg[0].length) {
            if (e.includes('http')) e = ` <a href="${e}" target="_blank">${e}</a>`;
            else e = ` <a href="http://${e}" target="_blank">${e}</a>`;
          } else if (!e.match(regEmail) && auxReg2 && e.length === auxReg2[0].length) {
            if (e.includes('http')) e = ` <a href="${e}" target="_blank">${e}</a>`;
            else e = ` <a href="http://${e}" target="_blank">${e}</a>`;
          }
        }
        aux2 += e + ' ';
      });

      return (aux += ' ' + aux2 + '\n');
    });

    if (pRef && pRef.current) {
      pRef.current.innerHTML = aux;
    }
  };

  const openImage = () => {
    const link = document.createElement('a');
    link.href = message;
    link.target = '_blank';
    link.click();
  };

  useEffect(() => {
    if (tipo === 'imagen') setTypeMessage(1);
    else if (tipo === 'video') setTypeMessage(2);
    else if (tipo === 'audio') setTypeMessage(3);
    else if (tipo === 'documento') setTypeMessage(4);
    else {
      setTypeMessage(0);
      validateURL();
    }
  }, [tipo]);

  return (
    <div
      className={
        styles.container + ' ' + (first ? styles.first : '') + ' ' + (last ? styles.last : '') + ' ' + (unique ? styles.unique : '')
      }
    >
      {last || unique ? <img className={styles.mini__logo} src="/logo_cliente.png" alt="Logo Xira" /> : <div className={styles.space} />}
      {/* {first || unique ? (
        <div className={styles.Nodo}>
          <p className={styles.texto}>Nodo: {nodoActual.toString()}</p>
        </div>
      ) : null} */}
      <div
        className={
          styles.message +
          ' ' +
          (first ? styles.first_message : '') +
          ' ' +
          (last ? styles.last_message : '') +
          ' ' +
          (unique ? styles.unique_message : '')
        }
      >
        {typeMessage === 0 ? (
          <p ref={pRef}></p>
        ) : typeMessage === 1 ? (
          <img
            className={
              (first ? styles.first_message : '') + ' ' + (last ? styles.last_message : '') + ' ' + (unique ? styles.unique_message : '')
            }
            src={message}
            alt="Imagen"
            onClick={openImage}
          />
        ) : typeMessage === 2 ? (
          <audio controls>
            {' '}
            <source src={message} />
          </audio>
        ) : typeMessage === 3 ? (
          <video controls>
            {' '}
            <source src={message} />
          </video>
        ) : typeMessage === 4 ? (
          <MessageDocument doc={message} />
        ) : null}
      </div>
    </div>
  );
};

export default MessageRobot;
