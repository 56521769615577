import axios, { AxiosRequestConfig } from 'axios';
import { ResponseGetConversation } from '../interfaces';

const getUrlChatbot = async (): Promise<string> => {
  const aux = await fetch(`config.json`);
  const aux2 = await aux.json();
  return aux2.URL_CHATBOT;
};

const sendMessage = async (senderId: string, message: string, canal: string = '5', senderName = "Web_Chat"): Promise<ResponseGetConversation[]> => {
  const opts: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
    },
  };
  console.log(await getUrlChatbot());
  
  const response = await axios.post(await getUrlChatbot(), { senderId, message, canal, senderName }, opts);
  for(let i = 0; i < response.data.respuestas.length; i++){
    if(response.data.nodoActual){
      response.data.respuestas[i].nodoActual = response.data.nodoActual
    }
  }
  console.log(response.data)
  return response.data.respuestas;
};

export { sendMessage };
