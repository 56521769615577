import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConvStateType } from '../../interfaces';

const initialState: ConvStateType[] = [];

const convSlice = createSlice({
  name: 'conv',
  initialState,
  reducers: {
    addMessage: (state: ConvStateType[], action: PayloadAction<ConvStateType>) => {
      state.push(action.payload);
    },
  },
});

export default convSlice.reducer;

export const { addMessage } = convSlice.actions;
