import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Chat } from './components';
import Icon from './components/icon/Icon';
import { store } from './redux';

import './index.css';

const rootIconReact = ReactDOM.createRoot(document.getElementById('root_icon_react') as HTMLElement);
rootIconReact.render(
  <React.StrictMode>
    <Provider store={store}>
      <Icon />
      {/* <Icon changeState={visibleChat} /> */}
    </Provider>
  </React.StrictMode>
);

const rootChatbotReact = ReactDOM.createRoot(document.getElementById('root_chatbot_react') as HTMLElement);

rootChatbotReact.render(
  <React.StrictMode>
    <Provider store={store}>
      <Chat />
    </Provider>
  </React.StrictMode>
);
