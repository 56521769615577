import React, { FC, useEffect } from 'react';

import { MessageRobot, MessageUser } from '../../containers';
import { PropsConversation } from '../../interfaces';
import { useAppSelector } from '../../redux';

import styles from './Conversation.module.css';

const Conversation: FC<PropsConversation> = () => {
  const conv = useAppSelector((state) => state.conv);

  const scrollAutomatic = (scrollTop: number) => {
    const conve = document.getElementById('conversation') || document.createElement('div');
    conve.scrollTo({
      top: scrollTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const conve = document.getElementById('conversation') || document.createElement('div');
    scrollAutomatic(conve.scrollHeight - conve.clientHeight);
  }, [conv]);

  return (
    <div className={styles.container} id="conversation">
      <div className={styles.space_blank} />

      {conv &&
        conv.map((conversation, idx) => {
          if (conversation.owner === 0)
            return conversation.messages.map((message, idx2) => {
              return (
                <MessageUser
                  message={message.message}
                  tipo={message.type}
                  key={`${idx}${idx2}`}
                  first={conversation.length !== 1 && idx2 === 0 ? true : false}
                  last={conversation.length !== 1 && idx2 === conversation.length - 1 ? true : false}
                  unique={conversation.length === 1 ? true : false}
                />
              );
            });
          else
            return conversation.messages.map((message, idx2) => {
              // console.log(message)
              return (
                <MessageRobot
                  message={message.message}
                  tipo={message.type}
                  key={`${idx}${idx2}`}
                  first={conversation.length !== 1 && idx2 === 0 ? true : false}
                  last={conversation.length !== 1 && idx2 === conversation.length - 1 ? true : false}
                  unique={conversation.length === 1 ? true : false}
                  nodoActual={message.nodoActual}
                />
              );
            });
        })}
    </div>
  );
};

export default Conversation;
