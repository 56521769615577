import React, { FC, useEffect, useState } from 'react';
import { IoDocumentText } from 'react-icons/io5';

import { PropsMessageDocument } from '../../interfaces';

import styles from './MessageDocument.module.css';

const MessageDocument: FC<PropsMessageDocument> = ({ doc }) => {
  const [nameFile, setNameFile] = useState<string>('');

  useEffect(() => {
    const lastIdx: number = doc.lastIndexOf('/');
    setNameFile(decodeURI(doc.substring(lastIdx + 1)));
  }, [doc]);

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = doc;
    link.download = nameFile;
    link.target = '_blank';
    link.click();
  };

  return (
    <div className={styles.container}>
      <IoDocumentText size={40} onClick={downloadFile} />
      <h3 onClick={downloadFile}>{nameFile}</h3>
      {/* <h3 onClick={downloadFile}>fokjadrhfjasdhjigndfrijgbnsdjkbgnlijsdghbnijdfsrbngvijdsrbngijdrbgnhujd</h3> */}
    </div>
  );
};

export default MessageDocument;
