import React, { FC } from 'react';

import { BiSend } from 'react-icons/bi';
import { PropsFooter } from '../../interfaces';

import styles from './Footer.module.css';

const Footer: FC<PropsFooter> = ({ handleSendMessage, messageText, setMessageText }) => {
  return (
    <div className={styles.container}>
      <input
        className={styles.message}
        placeholder="Escribe un mensaje..."
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
      />
      <BiSend
        size={30}
        onClick={() => {
          setMessageText('');
          handleSendMessage(messageText);
        }}
      />
    </div>
  );
};

export default Footer;
