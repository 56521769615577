import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStateType } from '../../interfaces';
import { InitializeUserData } from '../../utils';

const initialState: UserStateType = InitializeUserData();

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSenderId: (state: UserStateType, action: PayloadAction<UserStateType>) => {
      state.senderId = action.payload.senderId;
    },
  },
});

export default userSlice.reducer;

export const { setSenderId } = userSlice.actions;
