import React, { FC, useCallback, useEffect, useState } from 'react';

import { Header, Footer, Conversation } from '..';
import { sendMessage } from '../../api';
import { GroupMessage, Message, PropsChat, ResponseGetConversation } from '../../interfaces';
import { addMessage, setTitleChatbot, useAppDispatch, useAppSelector } from '../../redux';
import { InitializeGlobalData, InitializeGroupMessage, InitializeMessage } from '../../utils';

import styles from './Chat.module.css';

const Chat: FC<PropsChat> = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [refresh, setRefresh] = useState(false);
  const [messageText, setMessageText] = useState('');

  const handleSendMessage = async (text: string) => {
    if (text === '') return;

    const newMessage: Message = InitializeMessage(text, 'texto');
    const newGroupMessage: GroupMessage = InitializeGroupMessage(0, [newMessage]);
    dispatch(addMessage(newGroupMessage));

    sendMessage(user.senderId, text).then((respuestas: ResponseGetConversation[]) => {
      const newMessages: Message[] = [];
      respuestas.forEach((resp) => newMessages.push(InitializeMessage(resp.valor, resp.tipo, resp.nodoActual || 1)));
      dispatch(addMessage(InitializeGroupMessage(1, newMessages)));

      setRefresh(!refresh);
    });
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (key === 'Enter') {
      handleSendMessage(messageText);
      setMessageText('');
    }
  };

  const initApp = useCallback(async () => {
    const aux = await fetch(`config.json`);
    const aux2 = await aux.json();

    dispatch(setTitleChatbot(InitializeGlobalData('', aux2.TITLE_CHATBOT)));
  }, [dispatch]);

  useEffect(() => {
    initApp();
  }, [initApp]);

  return (
    <div className={styles.container} onKeyDownCapture={(e) => onKeyPress(e)} id="chat">
      <Header />
      <Conversation />
      <Footer handleSendMessage={handleSendMessage} messageText={messageText} setMessageText={setMessageText} />
    </div>
  );
};

export default Chat;
