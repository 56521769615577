import React, { FC, useEffect } from 'react';

import { PropsMessageUser } from '../../interfaces';

import styles from './MessageUser.module.css';

const MessageUser: FC<PropsMessageUser> = ({ message, first = false, last = false, unique = false }) => {
  const pRef = React.useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const auxMessage = message.split(/\n/g);

    const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/g;
    const regURL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    const regURL2 = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}:[0-9]{1,5}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    let aux = '';
    auxMessage.forEach((e2, idx) => {
      let aux2 = '';
      e2.split(/[\s\r]/g).forEach((e, idx2) => {
        const auxReg = e.match(regURL);
        const auxReg2 = e.match(regURL2);

        if (auxReg || auxReg2) {
          if (!e.match(regEmail) && auxReg && e.length === auxReg[0].length) {
            if (e.includes('http')) e = ` <a href="${e}" target="_blank">${e}</a>`;
            else e = ` <a href="http://${e}" target="_blank">${e}</a>`;
          } else if (!e.match(regEmail) && auxReg2 && e.length === auxReg2[0].length) {
            if (e.includes('http')) e = ` <a href="${e}" target="_blank">${e}</a>`;
            else e = ` <a href="http://${e}" target="_blank">${e}</a>`;
          }
        }
        aux2 += e + ' ';
      });

      return (aux += ' ' + aux2 + '\n');
    });

    if (pRef && pRef.current) {
      pRef.current.innerHTML = aux;
    }
  }, [message]);

  return (
    <div className={styles.container + ' ' + (first ? styles.first : '') + ' ' + (last ? styles.last : '')}>
      <div
        className={
          styles.message +
          ' ' +
          (first ? styles.first_message : '') +
          ' ' +
          (last ? styles.last_message : '') +
          ' ' +
          (unique ? styles.unique : '')
        }
      >
        <p ref={pRef}></p>
      </div>
    </div>
  );
};

// Buenos dias www.google.com como andamos?

export default MessageUser;
